<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-card style="min-height: 45rem">
          <el-table :data="group_list" border>
            <el-table-column prop="id" label="编号"></el-table-column>
            <el-table-column label="名称" prop="title"></el-table-column>
            <el-table-column label="操作" width="240" align="center">
              <template #header>
                <el-button @click="groupAdd" type="primary">添加</el-button>
              </template>
              <template #default="s">
                <el-button @click="manageItem(s.row)">管理子项</el-button>
                <el-button @click="groupEditItem(s.row,0)" type="primary">编辑</el-button>
                <el-button @click="groupDel(s.row)" type="danger">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
      <el-col :span="12" v-if="manageGroupId > 0">
        <el-card style="min-height: 45rem">
          <div class="y-desc" style="font-size: initial;font-weight: 600;margin-bottom: 1rem">
            #{{manageGroupId}}:{{group_list.filter(e=>e.id === manageGroupId)[0].title}}
          </div>
          <el-table :data="item_list" border>
            <el-table-column prop="id" label="编号"></el-table-column>
            <el-table-column label="名称" prop="title"></el-table-column>
            <el-table-column label="链接" prop="link"></el-table-column>
            <el-table-column label="操作" align="center">
              <template #header>
                <el-button @click="itemAdd" type="primary">添加</el-button>
              </template>
              <template #default="s">
                <el-button @click="itemEditItem(s.row)" type="primary">编辑</el-button>
                <el-button @click="itemDel(s.row)" type="danger">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>
    <el-dialog title="编辑分组" :visible.sync="groupEditFormBox" @close="groupEditFormClose">
      <el-form label-width="3rem" style="padding: 0 2rem">
        <el-form-item label="名称">
          <el-input v-model="groupForm.title"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="groupEditFormBox = false">取 消</el-button>
        <el-button type="primary" @click="groupEdit">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="编辑" :visible.sync="itemEditFormBox" @close="itemEditFormClose">
      <el-form label-width="3rem" style="padding: 0 2rem">
        <el-form-item label="名称">
          <el-input v-model="itemForm.title"></el-input>
        </el-form-item>
        <el-form-item label="链接">
          <YWebsiteChooseLink v-model="itemForm.link"></YWebsiteChooseLink>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="itemEditFromBox = false">取 消</el-button>
        <el-button type="primary" @click="itemEdit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import YWebsiteChooseLink from "@/components/website/yWebsiteChooseLink";

const GroupEditFormTpl = {
  title:"",
}
const ItemEditFormTpl = {
  group_id:0,
  title:"",
}

export default {
  name: "bottomNav",
  components:{
    YWebsiteChooseLink,
  },
  data() {
    return {
      group_list:[],
      groupForm:{...GroupEditFormTpl},
      groupEditFormBox:false,
      itemForm:{...ItemEditFormTpl},
      itemEditFormBox:false,
      item_list:[],

      manageGroupId:0,
    }
  },
  mounted() {
    this.groupLoad();
  },
  methods: {
    groupAdd(){
      this.groupEditFormBox=true;
      this.manageGroupId=0;
      this.itemForm.group_id = 0;
    },
    itemAdd(){
      this.itemEditFormBox = true;
      this.itemForm.group_id = this.manageGroupId;
    },
    manageItem({id}){
      this.manageGroupId = id;
      this.itemLoad();
    },
    groupLoad(){
      this.$u.api.website.bottomNavGroupAll().then(res=>{
        this.group_list = res;
      })
    },
    itemLoad(){
      if (!this.manageGroupId)return;
      this.item_list = [];
      this.$u.api.website.bottomNavItemAll({group_id: this.manageGroupId}).then(res=>{
        this.item_list = res;
      })
    },
    groupDel(item){
      this.$u.api.website.bottomNavGroupDel({id:item.id}).then(()=>{
        this.$message.success("操作成功");
        this.groupEditFormBox = false;
        this.groupLoad();
        this.manageGroupId = 0;
      })
    },
    itemDel(item){
      this.$u.api.website.bottomNavItemDel({id:item.id}).then(()=>{
        this.$message.success("操作成功");
        this.itemEditFormBox = false;
        this.itemLoad();
      })
    },
    groupEditItem(item){
      this.groupForm = {...item};
      this.groupEditFormBox = true;
    },
    itemEditItem(item){
      this.itemForm = {...item};
      this.itemForm.group_id = this.manageGroupId;
      this.itemEditFormBox = true;
    },
    groupEditFormClose(){
      this.groupForm = {...GroupEditFormTpl};
      this.groupEditFormBox = false;
    },
    itemEditFormClose(){
      this.itemForm = {...ItemEditFormTpl};
      this.itemEditFormBox = false;
    },
    itemEdit(){
      this.$u.api.website.bottomNavItemEdit(this.itemForm).then(()=>{
        this.$message.success("操作成功");
        this.itemEditFormBox = false;
        this.itemLoad();
      })
    },
    groupEdit(){
      this.$u.api.website.bottomNavGroupEdit(this.groupForm).then(()=>{
        this.$message.success("操作成功");
        this.groupEditFormBox = false;
        this.groupLoad();
      })
    },
  }
}
</script>

<style scoped>

</style>