<template>
  <div>
    <el-input disabled size="mini" placeholder="请选择链接" :value="value">
      <template slot="append">
        <div @click="dialogVisible=true" class="y-pointer">选择链接</div>
      </template>
    </el-input>
    <el-dialog custom-class="custom" append-to-body title="选择链接" :show-close="false" :visible.sync="dialogVisible" width="50rem">
      <el-tabs v-model="activeName" style="height: 20rem">
        <el-tab-pane label="系统链接" name="system">
          <template v-for="(item,key) in system">
            <el-button style="margin: .5rem" @click="chooseSys(key)" :key="key">{{item.title}}</el-button>
          </template>
        </el-tab-pane>
        <el-tab-pane v-if="useCustom" label="自定义链接" name="custom">
          <div class="flex-def flex-zCenter flex-cCenter" style="height: 10rem">
            <el-input style="width: 30rem" placeholder="请输入" v-model="custom">
              <template slot="append">
                <el-button @click="sureCustom" style="padding:0 3rem;" size="mini">确认</el-button>
              </template>
            </el-input>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "yWebsiteChooseLink",
  model:{
    prop:"modelval",
    event:'change'
  },
  props:{
    useCustom:{
      type:Boolean,
      default: true
    },
    modelval: {
      type:String,
      default:""
    },
  },
  watch:{
    modelval(){
      this.value = this.modelval;
    },
    value(e){
      this.$emit('change',e);
    }
  },
  data(){
    return{
      value: this.modelval,
      custom:"",
      dialogVisible:false,
      activeName:"system",
      system:[
        {title:"首页",link:"/"},
        {title:"画册列表页",link:"/website/album"},
        {title:"文章列表页",link:"/website/article"},
        {title:"商品列表页",link:"/website/goods"},
        {title:"视频列表页",link:"/website/video"},
      ],
    }
  },
  mounted() {
  },
  methods:{
    sureCustom(){
      this.dialogVisible=false;
      this.value = this.custom;
    },
    chooseSys(key){
      let item = this.system[key];
      this.value = item.link;
      this.dialogVisible = false;
    },
  }
}
</script>
<style>
.custom>.el-dialog__body{
  padding: 0 20px 30px !important;
  margin: 0;
}
</style>
<style scoped>

</style>